import './template.min.css'

const isFR = document.documentElement.lang == 'fr'
let gsapData = null

if (isFR) {
    try {
        gsapData = await import('./../../fr/node_modules/gsap')
    } catch (error) {
        console.log(error)
    }
} else {
    try {
        gsapData = await import('./../../en/node_modules/gsap')
    } catch (error) {
        console.log(error)
    }
}
const gsap = gsapData.default

let scrollTriggerData = null
if (isFR) {
    try {
        scrollTriggerData = await import('./../../fr/node_modules/gsap/ScrollTrigger.js')
    } catch (error) {
        console.log(error)
    }
} else {
    try {
        scrollTriggerData = await import('./../../en/node_modules/gsap/ScrollTrigger.js')
    } catch (error) {
        console.log(error)
    }
}
const ScrollTrigger = scrollTriggerData.default

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollTrigger)

window.jumpTo = window.scrollTo
window.scrollTo = () => {
    // console.warn('"window.scrollTo" function has been temporarily disabled.')
}

// DOM elements
const navCover = document.querySelector('.nav-cover')
const hugeTitle = document.querySelector('.huge-title')
const scrollIndicator = document.querySelector('.scroll-indicator')
const language = document.querySelector('.language')
const navLinks = document.querySelectorAll('nav a')
const bgImage = document.querySelector('.background-image')
const content = document.querySelector('.content-container')
const menu = document.querySelector('.menu')
const topLeft = document.querySelectorAll('.menu .top.left')
const topRight = document.querySelectorAll('.menu .top.right')
const bottomLeft = document.querySelectorAll('.menu .bottom.left')
const bottomRight = document.querySelectorAll('.menu .bottom.right')
const donateContainer = document.querySelector('.donate-container')
const footerYear = document.querySelector('.footer .year')
if (footerYear) {
    footerYear.innerText = new Date().getFullYear()
}

// Variables
let smallScreen = false
let isFirstRecalculation = true

// Prevent saving the scroll position
history.scrollRestoration = 'manual'
// Force a reload to prevent BFcache
window.addEventListener('pageshow', (e) => {
    if (e.persisted) {
        location.reload()
    }
})

if (scrollIndicator) {
    setTimeout(() => {
        scrollIndicator.style.visibility = 'visible'
        scrollIndicator.style.opacity = 0.6
    }, 2000)
}

// Scroll to hash
if (location.hash) {
    setTimeout(() => {
        location.href = location.pathname + location.hash
    }, 100)
}

const lazyImages = document.querySelectorAll("[loading='lazy']:not(.no-refresh)")
lazyImages.forEach((img) => {
    img.addEventListener('load', () => {
        // console.log('refreshed')
        ScrollTrigger.refresh()
    })
})

language.addEventListener('click', (e) => {
    let pathname = location.pathname
    if (pathname.slice(-1) == '/') {
        pathname = location.pathname.slice(0, -1)
    }
    location.href = location.origin + '/' + e.target.textContent.toLowerCase() + pathname
})

navLinks.forEach((link) => {
    // Check if user on same page as nav link, do not check if there is hash
    if (link.hash) return

    if (location.pathname === link.pathname || location.pathname === `${link.pathname}/` || location.pathname === `${link.pathname}/index.html`) {
        link.classList.add('selected')
    }
})

// Get enter presses on focused elements
window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
        e.preventDefault()
        if (document.activeElement) {
            document.activeElement.click()
        }
    }
})

// Menu button hover effect and click listener
menu.addEventListener('mouseenter', menuHover)
menu.addEventListener('focus', menuHover)
menu.addEventListener('mouseleave', menuReset)
menu.addEventListener('focusout', menuReset)
menu.addEventListener('click', () => {
    document.body.classList.add('user-interaction')
    toggleNav(document.body.classList.contains('menu-open'))
})

// Save initial values with gsap
if (bgImage) {
    gsap.set(bgImage, {
        x: 0,
        y: 0,
        xPercent: 0,
        yPercent: 0,
    })
}
gsap.set(menu, {
    opacity: 0.6,
    x: () => {
        return smallScreen ? '100vw' : '12em'
    },
    y: 0,
    xPercent: () => {
        return smallScreen ? -185 : 0
    },
    yPercent: 15,
    rotation: -90,
})
gsap.set([topLeft, bottomLeft, topRight, bottomRight], {
    backgroundColor: '#4d23b0',
    x: 0,
    y: (index) => {
        return `${(index % 2) * 0.1 - 0.1}em`
    },
    xPercent: 0,
    yPercent: 400,
    rotation: (index) => {
        return index < 2 ? -45 : 45
    },
})

// Hover effect for menu button
function menuHover() {
    gsap.to(menu, {
        opacity: 1,
        duration: 0.4,
        ease: 'linear',
    })

    gsap.to([topLeft, bottomLeft, topRight, bottomRight], {
        xPercent: 0,
        yPercent: (index) => {
            return 200 * (index % 2) + 200
        },
        duration: 0.4,
        ease: 'power2.out',
    })
}
function menuReset() {
    gsap.to(menu, {
        opacity: 0.6,
        duration: 0.4,
        ease: 'linear',
    })

    gsap.to([topLeft, bottomLeft, topRight, bottomRight], {
        xPercent: 0,
        yPercent: 400,
        duration: 0.4,
        ease: 'power2.out',
    })
}

// Timeline for navbar animation on click
const menuOpenAnimation = new gsap.timeline({
    paused: true,
    onComplete: () => {
        ScrollTrigger.refresh()
    },
    onReverseComplete: () => {
        ScrollTrigger.refresh()
    },
})
// Burger to arrows animation on click
menuOpenAnimation.fromTo(
    menu,
    {
        x: '1em',
        y: 0,
        xPercent: 0,
        yPercent: 0,
        rotation: 0,
    },
    {
        x: () => {
            return smallScreen ? '100vw' : '12em'
        },
        y: 0,
        xPercent: () => {
            return smallScreen ? -185 : 0
        },
        yPercent: 15,
        rotation: -90,
        duration: 0.5,
        ease: 'power1.inOut',
    }
)
// menuOpenAnimation.fromTo(
//     [topLeft, bottomLeft, topRight, bottomRight],
//     { rotation: 0 },
//     {
//         rotation: (index) => {
//             return index < 2 ? -45 : 45
//         },
//         duration: 0.4,
//         ease: 'power2.inOut',
//     },
//     '<'
// )
menuOpenAnimation.fromTo(
    [topLeft, bottomLeft, topRight, bottomRight],
    {
        backgroundColor: '#ffffff',
        x: 0,
        y: 0,
        rotation: 0,
    },
    {
        backgroundColor: '#4d23b0',
        x: 0,
        y: (index) => {
            return `${(index % 2) * 0.1 - 0.1}em`
        },
        rotation: (index) => {
            return index < 2 ? -45 : 45
        },
        duration: 0.5,
        ease: 'linear',
    },
    '<'
)
menuOpenAnimation.progress(1, true)

let hideContentAnimation = new gsap.timeline({
    paused: true,
})
if (bgImage) {
    hideContentAnimation.fromTo(
        bgImage,
        {
            x: 0,
            y: 0,
            xPercent: 0,
            yPercent: 0,
        },
        {
            x: 0,
            y: 0,
            xPercent: 100,
            yPercent: 0,
            duration: 0.4,
            ease: 'power2.inOut',
        }
    )
}
if (donateContainer) {
    hideContentAnimation.fromTo(
        donateContainer,
        {
            x: 0,
            y: 0,
            xPercent: 0,
            yPercent: 0,
        },
        {
            x: 0,
            y: 0,
            xPercent: 120,
            yPercent: 0,
            duration: 0.4,
            ease: 'power2.inOut',
        },
        '<'
    )
}
hideContentAnimation.fromTo(
    hugeTitle ? [content, hugeTitle, navCover] : [content, navCover],
    {
        right: 0,
    },
    {
        right: '-100vw',
        duration: 0.4,
        ease: 'power2.inOut',
        onComplete: () => {
            document.documentElement.style.overflow = 'hidden'
        },
    },
    '<'
)

function toggleNav(toggleOff) {
    if (toggleOff) {
        if (isFirstRecalculation && !document.body.classList.contains('user-interaction')) {
            // Set menu to closed state
            menuOpenAnimation.progress(0, true)
            isFirstRecalculation = false
        } else {
            menuOpenAnimation.reverse()
        }
        document.body.classList.remove('menu-open')
        menu.title = 'Menu'
        navLinks.forEach((link) => {
            link.tabIndex = -1
        })

        if (smallScreen) {
            document.documentElement.style.overflow = 'initial'
            hideContentAnimation.reverse()
        }
    } else {
        // Reset start values and play animation
        menuOpenAnimation.invalidate()
        menuOpenAnimation.restart()
        document.body.classList.add('menu-open')
        menu.title = isFR ? 'Cacher le menu' : 'Hide Menu'
        navLinks.forEach((link) => {
            link.tabIndex = 0
        })

        if (smallScreen) {
            hideContentAnimation.restart()
        }
    }
}

// window.addEventListener('click', () => {
//     menuOpenAnimation.invalidate()
//     menuOpenAnimation.progress(0, true).progress(1, true)
// })

// Resize
window.addEventListener('resize', recalculateSize)
recalculateSize()

function recalculateSize() {
    if (window.matchMedia('(max-width: 60em)').matches) {
        // Do nothing if small screen already detected
        if (smallScreen) return
        smallScreen = true

        if (document.body.classList.contains('menu-open')) {
            if (document.body.classList.contains('user-interaction')) {
                // console.log('Screen too small, nav was already opened by user, full screened. ')
                hideContentAnimation.restart()
                menuOpenAnimation.invalidate()
                menuOpenAnimation.progress(0, true).progress(1, true)
            } else {
                // console.log('Screen too small, nav was already opened by default, closed. ')
                toggleNav(true)
            }
        }
        return
    }

    // if (isFirstRecalculation) {
    //     // Set menu to open state
    //     menuOpenAnimation.progress(1, true)
    //     isFirstRecalculation = false
    // }

    // Do nothing if large screen already detected
    if (!smallScreen) return
    smallScreen = false

    if (document.body.classList.contains('menu-open')) {
        // console.log('Screen too large, nav was already opened, reduced to sidebar. ')
        document.documentElement.style.overflow = 'initial'
        hideContentAnimation.reverse()
        menuOpenAnimation.invalidate()
        menuOpenAnimation.progress(0, true).progress(1, true)
    } else if (!document.body.classList.contains('user-interaction')) {
        // console.log('Screen too large, nav was closed by default, opened. ')
        toggleNav(false)
    }
}

// Redirect donation button clicks to open popup
const donateButtons = document.querySelectorAll('.paypal-popup')
const paypalDonate = document.querySelector('#donate-button')

for (let i = 0; i < donateButtons.length; i++) {
    let button = donateButtons[i]
    // Map enter key to button click
    button.addEventListener('keyup', (e) => {
        if (e.keyCode === 13) {
            paypalDonate.click()
        }
    })
    button.addEventListener('click', (e) => {
        paypalDonate.click()
    })
}

// Signature
console.log(
    '%c \u2727 David\u00A0Lu \u2727 ',
    `
    text-align: center;
    font-family: Brush Script MT, Brush Script, Brush Script MT Italic, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #ffffff;
    background: #4D23B0;
    text-shadow: 2px 2px 2px #00000080;
    `
)
